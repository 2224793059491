import React, { useState, useEffect } from 'react';
import '../Css/FrameThree.css';
import Logo from '../Photo/Logo.png';
import tovarZer from '../Photo/pngwing 2.png';
import tovarZerDva from '../Photo/Group 27346.png';
import ponchik from '../Photo/пончик 1.png';
import coffeebig2 from '../Photo/cans_ina_air1 2.png';
import mutnik from '../Photo/klipartz 7 (2).png';
import mobilelogo from '../Photo/Group.png';
import togle from '../Photo/Component 15.png';

export const FrameThree = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(prevState => !prevState);
  };

  const navigateToSection = (sectionId) => {
    let title = '';
    switch(sectionId) {
      case 'About-us-frame':
        title = 'About Us | 21Coffee';
        break;
      case 'Coffee-beans':
        title = 'Coffee Beans | 21Coffee';
        break;
      case 'reserve': // Здесь было пропущено двоеточие
        title = 'Reserve | 21Coffee';
        break;
      case 'contact-us-footer': // Добавил этот case для полноты примера
        title = 'Contact | 21Coffee';
        break;
      // Добавьте другие секции по мере необходимости
      default:
        title = '21Coffee';
    }
    document.title = title;

    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      const sections = [
        {id: 'About-us-frame', title: '21Coffee | Main'},
        {id: 'menuTAg', title: 'Menu | 21Coffee'},
        {id: 'About-us-frame', title: 'About Us | 21Coffee'}, 
        {id: 'Coffee-beans', title: 'Coffee Beans | 21Coffee'},
        {id: 'reserve', title: 'Reserve | 21Coffee'},
        {id: 'contact-us-footer', title: 'Contact | 21Coffee'}
      ];
      const scrollPosition = window.scrollY + window.innerHeight / 2;

      for (let i = 0; i < sections.length; i++) {
        const section = document.getElementById(sections[i].id);
        if (section.offsetTop <= scrollPosition && section.offsetTop + section.offsetHeight > scrollPosition) {
          document.title = sections[i].title;
          break;
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
 
    const [offsetY, setOffsetY] = useState(0);
    const isDesktop = window.innerWidth >= 768; // Пороговое значение для мобильных устройств

    const handleScroll = () => {
      if (isDesktop) {
        const speed = 0.1; 
        setOffsetY(window.pageYOffset * speed);
      }
    };

    useEffect(() => {
      if (isDesktop) {
        window.addEventListener('scroll', handleScroll);
      }

      return () => {
        if (isDesktop) {
          window.removeEventListener('scroll', handleScroll);
        }
      };
    }, [isDesktop]);
    

  return (
    <div id='About-us-frame' className='frame-three-container'>
        <img id='mobile-logo' src={mobilelogo} alt="" />
        <img id='coffecontrol' src={coffeebig2} alt="" 
        style={{ 
            transform: `translateY(${offsetY}px)`,
            transition: 'transform 0.2s ease-out' // Добавляет плавность движения
          }} />
        <img id='mutnik3' src={mutnik} alt="" 
        style={{ 
            transform: `translateY(${offsetY}px)`,
            transition: 'transform 0.2s ease-out' // Добавляет плавность движения
          }} />
          
        <div className='text-content'>
            <h3>About us</h3>
            
            <p><img id='logotut' src={Logo} alt="" /> <span className='mobile-hidden'>-</span> Your best choice!</p>
            <span>Where the heart meets the art of coffee. <br className='desktop-hidden' /> Here, every sip is a warm <br className='mobile-hidden' />
            hug, and <br className='desktop-hidden' />our smiles serve as the perfect <br className='desktop-hidden' /> blend with your brew. <br className='desktop-hidden' /> Let's make <br className='mobile-hidden'/>
            memories over cups filled <br className='desktop-hidden' /> with joy and crafted with love.
            <br /> Save the moment with us</span>
        </div>
        <div className='main-block-three'>
            <div className='content-text-block'>
                <div className='content-osn'>
                    <img src={tovarZer} alt="" />
                    <div className='content-osn-text'>
                        <h4>Freshly Roasted <br />
                            Coffee:</h4>
                            <p>We exclusively provide freshly roasted coffee beans, ensuring 
                              an unparalleled richness in taste and aroma. Here, you can explore a diverse 
                              range of varieties and blends to discover your ideal coffee</p>
                    </div>
                </div>
            </div>
            <div className='content-text-block'>
            <div className='content-osn'>
                    <img src={tovarZerDva} alt="" />
                    <div className='content-osn-text'>
                        <h4>Different coffee <br />
                            varieties:</h4>
                            <p>We exclusively source high-quality Arabica beans with 
                              superior ratings from an array of countries such as Brazil, 
                              Guatemala, Ethiopia, Colombia, Kenya, and Costa Rica. This diverse 
                              selection ensures that each cup offers a distinct and exceptional taste 
                              experience, celebrating the rich coffee cultures from around the globe</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}
