import React, { useState, useEffect } from 'react';
import '../Css/FrameTwo.css';
import moneykrona from '../Photo/kronamoney.png'
import pravo from '../Photo/krugright.png';
import vector from '../Photo/Vector.png';
import krujkaOne from '../Photo/krujka1.png';
import kofe from '../Photo/cans_ina_air 1.png';
import kofe2 from '../Photo/double espresso.png'
import kofe3 from '../Photo/Americano.png'
import kofe4 from '../Photo/Lungo 1.png'
import kofe5 from '../Photo/Espresso Macchiatto 1.png'
import kofe6 from '../Photo/Flat white 1.png'
import kofe7 from '../Photo/Cappucino 1.png'
import kofe8 from '../Photo/Latte 1.png'
import kofe9 from '../Photo/sssss.png'
import kofe10 from '../Photo/Raf 1.png'
import kofe11 from '../Photo/Irish Coffee 1.png'
import kofe12 from '../Photo/Batch brew 1.png'

export const FrameTwo = () => {
  const [offsetY, setOffsetY] = useState(0);
    const isDesktop = window.innerWidth >= 768; // Пороговое значение для мобильных устройств

    const handleScroll = () => {
      if (isDesktop) {
        const speed = 0.1; 
        setOffsetY(window.pageYOffset * speed);
      }
    };

    useEffect(() => {
      if (isDesktop) {
        window.addEventListener('scroll', handleScroll);
      }

      return () => {
        if (isDesktop) {
          window.removeEventListener('scroll', handleScroll);
        }
      };
    }, [isDesktop]);
  const [showMore, setShowMore] = useState(false);
  const handleCardClick = () => {
    const reserveSection = document.getElementById('reserve');
    if (reserveSection) {
      // Плавная прокрутка к элементу с id 'reserve'
      reserveSection.scrollIntoView({ behavior: 'smooth' });
    }
  };
  const products = [
    {
      name: 'Espresso',
      descriptionCZ: 'Jednorázová extrakce cerstvé mieté kavy',
      descriptionEN: 'Single extraction of fresh ground coffee',
      price: '60 CZK',
      image: krujkaOne,
    },
    {
      name: 'Double Espresso',
      descriptionCZ: 'dvojitá dávka vaseho oblibeného espressa',
      descriptionEN: 'Double shot of your favorite espresso',
      price: '70 CZK',
      image: kofe2,
    },
    {
      name: 'Americano',
      descriptionCZ: 'Horká voda pridaná do dvojitého espressa',
      descriptionEN: 'Hot water added to a double espresso',
      price: '65 CZK',
      image: kofe3,
    },
    {
      name: 'Lungo',
      descriptionCZ: 'Panák espressa s velkym prütokem vody',
      descriptionEN: 'A shot of espresso with a large flow of water',
      price: '70 CZK',
      image: kofe4,
    },
    {
      name: 'Espresso Macchiatto',
      descriptionCZ: 'Espresso s krátkou porci napareného mléka',
      descriptionEN: 'Espresso with a short serving of steamed milk',
      price: '65 CZK',
      image: kofe6,
    },
    {
      name: 'Flat white',
      descriptionCZ: 'Kávovy nápoj na bázi dvojitého espressa s pridanym mlékem',
      descriptionEN: 'A coffee drink based on double espresso with added milk',
      price: '80 CZK',
      image: kofe7,
    },
    {
      name: 'Cappucino',
      descriptionCZ: 'Dokonalá rovnovaha espressa a mléka',
      descriptionEN: 'The perfect balance of espresso and milk',
      price: '75 CZK',
      image: kofe5,
    },
    {
      name: 'Latte',
      descriptionCZ: 'Espresso pridané do napareného mléka (steam & milk)',
      descriptionEN: 'Espresso added to steamed milk (steam & milk)',
      price: '80 CZK',
      image: kofe8,
    },
    {
      name: 'Mochaccino',
      descriptionCZ: 'Espresso s dusenym mlékem a cokoládovym sirupem',
      descriptionEN: 'Espresso with steamed milk and chocolate',
      price: '80 CZK',
      image: kofe9,
    },
    {
      name: 'Raf',
      descriptionCZ: 'Nápoj sestávajici ze smetany, vanilkového cukru nebo sirupu a kávy espresso',
      descriptionEN: 'A drink consisting of cream, vanilla sugar or syrup and espresso coffee',
      price: '99 CZK',
      image: kofe10,
    },
    {
      name: 'Irish Coffee',
      descriptionCZ: 'Káva, irska whisky, cukr a prelité šlehačkou',
      descriptionEN: 'Irish Coffee is a captivating blend of hot coffee, Irish whiskey, sugar, and lightly whipped cream',
      price: '130 CZK',
      image: kofe11,
    },
    {
      name: 'Batch brew',
      descriptionCZ: 'Filtrovaná káva',
      descriptionEN: 'Filter coffee',
      price: '80 CZK',
      image: kofe12,
    },
    // Дополните список продуктов по аналогии
  ];

  return (
    <div id='menuTAg' className='frame-two-container'>
      <img id='krujka-main' 
        src={kofe} 
        alt="" 
        style={{ 
          transform: `translateY(${offsetY}px)`,
          transition: 'transform 0.1s ease-out' // Добавляет плавность движения
        }} 
        />
      <div className='text-start'>
        <h2>Coffee on our menu</h2>
        <p>We have unique coffee <br className='desktop-hide'/> recipes on our menu</p>
      </div>
      <div className='blocks-kart'>
  {products.slice(0, 3).map((product, index) => (
    <div key={index} className='content-kart' onClick={handleCardClick}>
      <img src={product.image} alt={product.name} />
      <h3>{product.name}</h3>
      <p>{product.descriptionCZ}</p>
      <p className="description-english">{product.descriptionEN}</p>
      <span><b>{product.price}</b></span>
    </div>
        ))}
      </div>
      {showMore && (
        <div className={`blocks-kart ${showMore ? 'expanded' : 'collapsed'}`}>
          {products.slice(3).map((product, index) => (
            <div key={index} className='content-kart'onClick={handleCardClick}>
              <img src={product.image} alt={product.name} />
              <h3>{product.name}</h3>
              <p>{product.descriptionCZ}</p>
      <p className="description-english">{product.descriptionEN}</p>
              <span><b>{product.price}</b></span>
            </div>
          ))}
        </div>
      )}
      <div className='sticks'>
        <button onClick={() => setShowMore(!showMore)}>More Menu <img id='vector1' src={vector} alt="" /></button>
      </div>
    </div>
  );
};
