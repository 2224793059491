import React from 'react'
import Logo from '../Photo/Logo.png';
import '../Css/Navbar.css'

export const Navbar = () => {
  return (
    <div className='container-navbar'>
        <div className='flex-navbar'>
        <div className='img-div'>
            <img src={Logo} alt="" />
        </div>
        <div className='link-navbar'>
           <a href="#About-us-frame">About Us</a>
           <a href="#Coffee-beans">Coffee beans</a>
           <a href="#reserve">Reserve</a>
           <a href="#contact-us-footer">Contact us</a>
        </div>
        </div>
    </div>
  )
}
