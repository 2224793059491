import React, { useState, useEffect } from 'react';
import '../Css/FrameOne.css';
import Logo from '../Photo/Logo.png';
import Coffee from '../Photo/CoffeCup.png';
import zernomal from '../Photo/klipartz 4.png';
import zernomal2 from '../Photo/klipartz 5.png';
import zernomal3 from '../Photo/klipartz 1.png';
import zernomal4 from '../Photo/klipartz 2.png';
import zernomal5 from '../Photo/klipartz 3.png';
import vector from '../Photo/Vector.png';
import togle from '../Photo/Component 15.png';

export const FrameOne = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(prevState => !prevState);
  };

  const navigateToSection = (sectionId) => {
    let title = '';
    switch(sectionId) {
      case 'About-us-frame':
        title = 'About Us | 21Coffee';
        break;
      case 'Coffee-beans':
        title = 'Coffee Beans | 21Coffee';
        break;
      case 'reserve': // Здесь было пропущено двоеточие
        title = 'Reserve | 21Coffee';
        break;
      case 'contact-us-footer': // Добавил этот case для полноты примера
        title = 'Contact | 21Coffee';
        break;
      // Добавьте другие секции по мере необходимости
      default:
        title = '21Coffee';
    }
    document.title = title;

    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      const sections = [
        {id: '21Coffee', title: '21Coffee | Main'},
        {id: 'menuTAg', title: 'Menu | 21Coffee'},
        {id: 'About-us-frame', title: 'About Us | 21Coffee'}, 
        {id: 'Coffee-beans', title: 'Coffee Beans | 21Coffee'},
        {id: 'reserve', title: 'Reserve | 21Coffee'},
        {id: 'contact-us-footer', title: 'Contact | 21Coffee'}
      ];
      const scrollPosition = window.scrollY + window.innerHeight / 2;

      for (let i = 0; i < sections.length; i++) {
        const section = document.getElementById(sections[i].id);
        if (section.offsetTop <= scrollPosition && section.offsetTop + section.offsetHeight > scrollPosition) {
          document.title = sections[i].title;
          break;
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div id='21Coffee' className="frame-one-container">
      <img id='zernomal1' src={zernomal} alt="" />
      <img id='zernomal2' src={zernomal2} alt="" />
      <img id='zernomal3' src={zernomal3} alt="" />
      <img id='zernomal4' src={zernomal4} alt="" />
      <img id='zernomal5' src={zernomal5} alt="" />
      {/* <img id='mutnoe1' src={MutnoeOdin} alt="Мутное1" />
      <img id='mutnoe2' src={MutnoeDva} alt="Мутное2" /> */}
      <div className="navbar">
  <button className="menu-button" onClick={toggleMenu}>
    <img src={togle} alt="" />
  </button>
  <img id='logo-desktop' src={Logo} alt="Логотип" className="logo"/>
  <div id='linki-linki' className={`links ${menuOpen ? 'open' : ''}`}>
    <a href="#About-us-frame">About Us</a>
    <a href="#Coffee-beans">Coffee beans</a>
    <a href="#reserve">Reserve</a>
    <a href="#contact-us-footer">Contact us</a>
  </div>
  
  <a href="" className="language-switch">EN</a>
</div>

      <div className='Content-one'>
        <div className='text-one'>
          <h1>Find comfort <br /> over a cup <br /> of our coffee</h1>
          <p>The taste of the coffee is wonderful, but <br /> 
          incomprehensible. You have to learn to <br /> 
          understand and love it, and only then <br /> 
          can you truly enjoy it to the fullest.</p>
          <button onClick={() => navigateToSection('Coffee-beans')}>
            Explore our coffee <img id='vector' src={vector} alt="" /></button>
        </div>
        <div className='Content-img'>
        <img id='coffeCup' src={Coffee} alt="КоффеБольшое" />
        </div>
      </div>
    </div>
  );
}
